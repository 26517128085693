import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// react components for routing our app without refresh
import { Link } from "gatsby"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Parallax from "components/Parallax/Parallax.jsx"
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import SectionBasics from "./Sections/SectionBasics.jsx"
import SectionNavbars from "./Sections/SectionNavbars.jsx"
import SectionTabs from "./Sections/SectionTabs.jsx"
import SectionPills from "./Sections/SectionPills.jsx"
import SectionNotifications from "./Sections/SectionNotifications.jsx"
import SectionTypography from "./Sections/SectionTypography.jsx"
import SectionJavascript from "./Sections/SectionJavascript.jsx"
import SectionCarousel from "./Sections/SectionCarousel.jsx"
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx"
import SectionLogin from "./Sections/SectionLogin.jsx"
import SectionExamples from "./Sections/SectionExamples.jsx"
import SectionDownload from "./Sections/SectionDownload.jsx"

// //added 
// import ListItem from "@material-ui/core/ListItem";
// import List from "@material-ui/core/List";
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";


import componentsStyle from "assets/jss/material-kit-react/views/components.jsx"
// import logo from "assets/img/logo-short.png"

class Components extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      <div>
        <Header
          brand="West Coast Beach Volleyball Club"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/beach2019.jpg")}>
          <div className={classes.container}>
            <GridContainer>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <SectionBasics />
          <SectionNavbars />
          <SectionTabs />
          <SectionPills />
          <SectionNotifications />
          <SectionTypography />
          <SectionJavascript />
          <SectionCarousel />
          <SectionCompletedExamples />
          <SectionLogin />
          <GridItem md={12} className={classes.textCenter}>
            <Link to={"/login-page"} className={classes.link}>
              <Button color="primary" size="lg" simple>
                View Login Page
              </Button>
            </Link>
          </GridItem>
          <SectionExamples />
          <SectionDownload />
          <Footer />
        </div>
      </div>
    )
  }
}

export default withStyles(componentsStyle)(Components)
